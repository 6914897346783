import {
  Box,
  Button,
  Icon,
  Theme,
  Typography,
  visuallyHidden,
} from "@outschool/backpack";
import { CurrencyCode } from "@outschool/gql-backend-generated";
import { faClock, faCoins, faGlobe, fasClock } from "@outschool/icons";
import { useLocalStorageState } from "@outschool/local-storage";
import {
  CURRENCIES_INFO,
  I18nLocale,
  SUPPORTED_LANGUAGES,
  SupportedLanguage,
  getLanguageFromLocale,
  getSuggestedCurrencyCodeForLocale,
  useCurrencyLocalization,
  useLocale,
  useSetCurrencyLocalization,
  useTranslation,
} from "@outschool/localization";
import {
  OUTSCHOOL_TIMEZONE,
  dayjs,
  formatIANATimeZoneName,
  formatIANATimeZoneParts,
  formatUTCOffset,
  getAllTimeZoneNames,
  guessBrowserTimeZone,
} from "@outschool/time";
import { TrackedButton } from "@outschool/ui-components-shared";
import {
  Select,
  SelectOption,
  SelectWithOptGroups,
} from "@outschool/ui-legacy-component-library";
import fromPairs from "lodash/fromPairs";
import sortBy from "lodash/sortBy";
import toPairs from "lodash/toPairs";
import uniqBy from "lodash/uniqBy";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { useGetInferredLocale } from "../hooks/useGetInferredLocale";
import PageModal from "./PageModal";

interface LocalizationPickerModalProps {
  isOpen: boolean;
  setIsOpen: (a: boolean) => void;
  storedCurrencyCode: CurrencyCode;
  storedTimeZone: string;
  applyChanges: (
    locale: I18nLocale,
    currencyCode: CurrencyCode,
    timeZone: string
  ) => Promise<void>;
}

const SELECTED_USER_LOCALE_LOCAL_STORAGE_KEY = "selectedUserLocale";
const TIME_ZONE_LOCAL_STORAGE_KEY = "timeZone";

const timeZoneOptionGroups: Record<string, SelectOption<string>[]> = {};
for (let timeZoneName of getAllTimeZoneNames()) {
  const [continent] = formatIANATimeZoneParts(timeZoneName);
  timeZoneOptionGroups[continent] ??= [];
  const timeZoneOption = {
    value: timeZoneName,
    label: `(${formatUTCOffset(timeZoneName)}) ${
      formatIANATimeZoneParts(timeZoneName)[1]
    }`,
  };
  timeZoneOptionGroups[continent].push(timeZoneOption);
}

// Sort timezones within optgroups and remove any duplicates
for (let continent in timeZoneOptionGroups) {
  const thisGroup = timeZoneOptionGroups[continent];
  const sortedGroup = sortBy(
    thisGroup,
    ({ value }) => -dayjs.tz(value).utcOffset()
  );
  const dedupedGroup = uniqBy(sortedGroup, "label");
  timeZoneOptionGroups[continent] = dedupedGroup;
}

const languageOptions = SUPPORTED_LANGUAGES.map(
  (language: SupportedLanguage) => ({
    value: language.i18nLocale,
    label: language.localLanguageName,
  })
);
const currencyOptions = Object.keys(CURRENCIES_INFO).map(
  (code: CurrencyCode) => ({
    value: code,
    label: `${CURRENCIES_INFO[code].name} (${code})`,
  })
);

const LocalizationPickerModal = ({
  isOpen,
  setIsOpen,
  storedCurrencyCode,
  storedTimeZone,
  applyChanges,
}: LocalizationPickerModalProps) => {
  const { t } = useTranslation(
    "ssr-client\\components\\FooterLocalizationButton"
  );

  const activeLocale = useLocale();
  const [selectedLanguageLocale, setSelectedLanguageLocale] =
    useState(activeLocale);
  const inferredLocale = useGetInferredLocale();
  useEffect(() => {
    if (inferredLocale && inferredLocale !== activeLocale) {
      setSelectedLanguageLocale(inferredLocale);
      setSelectedCurrencyCode(
        getSuggestedCurrencyCodeForLocale(inferredLocale)
      );
    }
  }, [inferredLocale, activeLocale]);

  const [selectedCurrencyCode, setSelectedCurrencyCode] =
    useState<CurrencyCode>(storedCurrencyCode);
  useEffect(() => {
    setSelectedCurrencyCode(storedCurrencyCode);
  }, [storedCurrencyCode]);

  const [selectedTimeZone, setSelectedTimeZone] = useState(
    storedTimeZone || OUTSCHOOL_TIMEZONE
  );
  useEffect(() => {
    setSelectedTimeZone(storedTimeZone);
  }, [storedTimeZone]);

  const selectedContinentTimeZone = toPairs(timeZoneOptionGroups).find(
    ([_continent, timeZones]) => {
      return timeZones.some(({ value }) => value === selectedTimeZone);
    }
  );

  let optGroups = timeZoneOptionGroups;
  if (selectedContinentTimeZone) {
    const [selectedContinent] = selectedContinentTimeZone;
    const pairs = toPairs(timeZoneOptionGroups);
    const sorted = sortBy(pairs, ([continent]) => {
      // Force the selected continent to be sorted first
      return continent === selectedContinent ? "." : continent;
    });
    optGroups = fromPairs(sorted);
  }

  const heading = t`Update Regional Settings`;
  return (
    <PageModal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      ariaLabel={heading}
      sx={{ maxWidth: 500, maxHeight: "100vh" }}
    >
      <Box>
        <Typography variant="h4" gutterBottom>
          {heading}
        </Typography>
        <Typography
          variant="inherit"
          sx={{
            display: "inline-block",
          }}
          gutterBottom
        >
          {t`Select language and currency you use to browse on Outschool.`}
        </Typography>
        <Typography variant="h6" gutterBottom>{t`Language`}</Typography>
        <Box
          sx={{
            position: "relative",
            flex: "auto",
          }}
        >
          <Select
            options={languageOptions}
            onChange={setSelectedLanguageLocale}
            value={selectedLanguageLocale}
            sx={{
              width: "100%",
              borderRadius: "5px",
              marginBottom: "20px",
              paddingLeft: "20px",
            }}
          />
          <Icon
            icon={faGlobe}
            sx={{
              position: "absolute",
              left: 15,
              top: "13px",
              color: "primary.700",
            }}
          />
        </Box>
        <Typography variant="h6" gutterBottom>{t`Currency`}</Typography>
        <Box
          sx={{
            position: "relative",
            flex: "auto",
          }}
        >
          <Select
            options={currencyOptions}
            onChange={setSelectedCurrencyCode}
            value={selectedCurrencyCode}
            sx={{
              width: "100%",
              borderRadius: "5px",
              marginBottom: "20px",
              paddingLeft: "20px",
            }}
          />
          <Icon
            icon={faCoins}
            sx={{
              position: "absolute",
              left: 15,
              top: "13px",
              color: "primary.700",
            }}
          />
        </Box>
        <Typography variant="h6" gutterBottom>{t`Time Zone`}</Typography>
        <Box
          sx={{
            position: "relative",
            flex: "auto",
          }}
        >
          <SelectWithOptGroups
            optGroups={optGroups}
            onChange={setSelectedTimeZone}
            value={selectedTimeZone}
            sx={{
              width: "100%",
              borderRadius: "5px",
              marginBottom: "20px",
              paddingLeft: "20px",
            }}
          />
          <Icon
            icon={faClock}
            sx={{
              position: "absolute",
              left: 15,
              top: "13px",
              color: "primary.700",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={() => setIsOpen(false)}>{t`Cancel`}</Button>
          <TrackedButton
            variant="contained"
            onClick={async () => {
              await applyChanges(
                selectedLanguageLocale,
                selectedCurrencyCode,
                selectedTimeZone
              );
            }}
            trackingName="apply_modal_localization_changes_button"
            trackingUniqueId="apply_modal_localization_changes_button"
            trackingData={{
              locale: activeLocale,
              storedCurrencyCode,
              storedTimeZone,
              selectedLanguageLocale,
              selectedCurrencyCode,
              selectedTimeZone,
            }}
            trackViews={true}
          >
            {t`Apply Changes`}
          </TrackedButton>
        </Box>
      </Box>
    </PageModal>
  );
};

export default function FooterLocalizationButton() {
  const { t } = useTranslation(
    "ssr-client\\components\\FooterLocalizationButton"
  );

  const a11yLabel = t`Open currency, time zone, and language settings`;
  const divider = (
    <Typography
      variant="inherit"
      sx={{
        margin: "0 7px",
      }}
    >
      {"|"}
    </Typography>
  );

  const [currencyCode, setCurrencyCode] = useState<CurrencyCode>(
    CurrencyCode.Usd
  );

  const { currencyCode: localStorageCurrencyCode } = useCurrencyLocalization();
  const { setCurrency: setLocalStorageCurrencyCode } =
    useSetCurrencyLocalization();
  useEffect(() => {
    setCurrencyCode(localStorageCurrencyCode);
  }, [localStorageCurrencyCode]);
  const currencySymbol = CURRENCIES_INFO[currencyCode]?.symbol;

  const [timeZone, setTimeZone] = useState<string>(OUTSCHOOL_TIMEZONE);
  const [localStorageTimeZone, setLocalStorageTimeZone] =
    useLocalStorageState<string>(
      TIME_ZONE_LOCAL_STORAGE_KEY,
      guessBrowserTimeZone()
    );
  useEffect(() => {
    setTimeZone(localStorageTimeZone);
  }, [localStorageTimeZone]);
  const timeZoneName = formatIANATimeZoneName(timeZone);

  const activeLocale = useLocale();
  const [locale, setLocale] = useState(activeLocale);
  const languageName = getLanguageFromLocale(locale).localLanguageName;
  const [_localStorageLocale, setLocalStorageLocale] = useLocalStorageState(
    SELECTED_USER_LOCALE_LOCAL_STORAGE_KEY,
    null
  );

  const [localizationMenuOpen, setLocalizationMenuOpen] =
    useState<boolean>(false);

  const inferredLocale = useGetInferredLocale();
  useEffect(() => {
    if (inferredLocale && inferredLocale !== activeLocale) {
      setLocalizationMenuOpen(true);
    }
  }, [inferredLocale, activeLocale]);

  const router = useRouter();
  const applyModalChanges = async (
    newLocale: I18nLocale,
    newCurrencyCode: CurrencyCode,
    newTimeZome: string
  ) => {
    await setLocalStorageCurrencyCode(newCurrencyCode);
    await setLocalStorageTimeZone(newTimeZome);
    await setLocalStorageLocale(newLocale);

    setCurrencyCode(newCurrencyCode);
    setTimeZone(newTimeZome);
    setLocale(newLocale);
    if (newLocale !== activeLocale) {
      router.push(router.asPath, router.asPath, { locale: newLocale });
      setLocalizationMenuOpen(false);
    } else {
      setLocalizationMenuOpen(false);
    }
  };

  return (
    <>
      <Box
        onClick={() => setLocalizationMenuOpen(true)}
        data-test-id="footer-localization-picker"
        sx={(theme: Theme) => ({
          fontSize: theme.typography.pxToRem(14),
          color: "common.white",
        })}
      >
        <button
          style={{
            background: "none",
            color: "inherit",
            border: "none",
            cursor: "pointer",
            padding: 0,
          }}
        >
          <span style={visuallyHidden}>{a11yLabel}</span>
          <Box
            sx={{
              display: "inline-block",
              verticalAlign: "middle",
              paddingRight: "0.5em",
            }}
          >
            <Icon
              aria-hidden
              icon={faGlobe}
              sx={{
                color: "common.white",
                backgroundColor: "primary.700",
                marginRight: "5px",
              }}
            />
            {languageName}
            {divider}
            <Typography
              variant="inherit"
              aria-hidden
              gutterBottom
              sx={{
                fontWeight: "fontWeightBold",
              }}
            >
              {currencySymbol}
            </Typography>
            {currencyCode}
            {divider}
            <Icon
              aria-hidden
              icon={fasClock}
              sx={{
                color: "common.white",
                backgroundColor: "primary.700",
                marginRight: "5px",
              }}
            />
            {timeZoneName}
          </Box>
        </button>
      </Box>
      <LocalizationPickerModal
        isOpen={localizationMenuOpen}
        setIsOpen={setLocalizationMenuOpen}
        storedCurrencyCode={currencyCode}
        storedTimeZone={timeZone}
        applyChanges={applyModalChanges}
      />
    </>
  );
}
