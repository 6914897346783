import { Box, Icon, SxProps } from "@outschool/backpack";
import { IconDefinition, faQuestionCircle, faSignIn } from "@outschool/icons";
import { TFunction, useTranslation } from "@outschool/localization";
import { loginPath } from "@outschool/routes";
import { getPostLoginPath } from "@outschool/ui-auth";
import { NavHelpLink } from "@outschool/ui-components-website";
import { useLinkComponent } from "@outschool/ui-utils";
import { SystemStyleObject } from "@styled-system/css";
import React, { ReactNode } from "react";

import JoinAndWelcomeButton from "../JoinAndWelcomeButton";
import HeaderNavItem from "./HeaderNavItem";
import LanguageSelector from "./LanguageSelector";

interface DesktopHeaderNavProps {
  joinButton?: ReactNode;
  showLanguageSelector?: boolean;
  isLoggedIn?: boolean;
}

export const DesktopHeaderNav: React.FC<DesktopHeaderNavProps> = ({
  joinButton,
  showLanguageSelector,
  isLoggedIn,
}) => {
  const { t } = useTranslation("ssr-client\\components\\nav\\DesktopHeaderNav");

  return (
    <NavItems
      sx={{
        gap: "1.25em",
      }}
    >
      <HelpLink t={t} hideLabel={!isLoggedIn} />
      {showLanguageSelector && <LanguageSelector />}
      {!isLoggedIn && <LoginLink t={t} />}
      {joinButton ? (
        joinButton
      ) : (
        <JoinAndWelcomeButton>{t`Join`}</JoinAndWelcomeButton>
      )}
    </NavItems>
  );
};

export function LoginLink({
  t,
  postLoginPath,
}: {
  t: TFunction;
  postLoginPath?: string;
}) {
  const Link = useLinkComponent();
  const [toPath, setToPath] = React.useState<string | null>(null);

  React.useEffect(() => {
    setToPath(loginPath(false, postLoginPath ?? getPostLoginPath()));
  }, [setToPath, postLoginPath]);

  return (
    <Link to={toPath} trackingName="nav_login_link">
      <HeaderNavItem>
        <HeaderNavLabel icon={faSignIn} label={t("Log In")} />
      </HeaderNavItem>
    </Link>
  );
}

export function HeaderNavLabel({
  label,
  icon,
  iconSx = {},
  hideLabel,
}: {
  label: string;
  icon?: IconDefinition;
  iconSx?: SxProps;
  hideLabel?: boolean;
}) {
  return (
    <Box
      flex
      sx={{
        alignItems: "center",
      }}
      aria-label={label}
    >
      {icon && (
        <Icon
          icon={icon}
          sx={[
            {
              fontSize: "1.333em",
              marginRight: hideLabel ? "none" : "0.5em",
            },
            ...(Array.isArray(iconSx) ? iconSx : [iconSx]),
          ]}
        />
      )}
      {!hideLabel && label}
    </Box>
  );
}

function NavItems({
  sx,
  children,
}: {
  sx?: SystemStyleObject;
  children: React.ReactNode[];
}) {
  return (
    <Box
      flex
      sx={{
        paddingY: "6px",
        "a:hover:not(:disabled),a:focus:not(:disabled)": {
          textDecoration: "none",
        },
        flex: 1,
        justifyContent: "end",
        alignItems: "center",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export function HelpLink({
  hideLabel = false,
  t,
}: {
  hideLabel: boolean;
  t: TFunction;
}) {
  return (
    <NavHelpLink sx={{ width: "auto" }}>
      <HeaderNavItem>
        <HeaderNavLabel
          icon={faQuestionCircle}
          label={t("Help")}
          hideLabel={hideLabel}
        />
      </HeaderNavItem>
    </NavHelpLink>
  );
}
