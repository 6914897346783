import { Box, Icon } from "@outschool/backpack";
import { faEnvelope } from "@outschool/icons";
import { isLocalStorageSupported } from "@outschool/local-storage";
import { useTranslation } from "@outschool/localization";
import { Component } from "@outschool/ownership-areas";
import {
  addParamsToUrl,
  joinParentAccountPath,
  joinTeacherAccountPath,
  loginPath,
  teachPath,
} from "@outschool/routes";
import {
  APP_STATE_POST_LOGIN_ACTION_LOCAL_STORAGE_NAME,
  ContinueWithApple,
  ContinueWithFacebook,
  ContinueWithGoogle,
  ContinueWithKakao,
  ContinueWithLine,
  LoginUserFeedback,
  getPostLoginPath,
  useAuthError,
  useCanShowGoogleAuth,
  useShouldShowKakao,
  useShouldShowLine,
  useTokenContext,
} from "@outschool/ui-auth";
import {
  AccountDisclaimer,
  TrackedButton,
} from "@outschool/ui-components-shared";
import { switchToLearnerModeWithToken } from "@outschool/ui-components-website";
import { useLinkComponent } from "@outschool/ui-utils";
import { TFunction, Trans } from "next-i18next";
import React from "react";

import {
  APPLE_SIGNIN_CLIENT_ID,
  FACEBOOK_APP_ID,
  FF_KAKAO_AUTH,
  FF_LINE_AUTH,
  GOOGLE_CLIENT_ID,
  isReviewApp,
} from "../../../Env";
import {
  useLoginWithApple,
  useLoginWithRedirect,
} from "../hooks/Authentication";

interface SignupFormProps {
  onRedirect?: (e: any) => void;
  promptLogin?: boolean;
  isTeacherFlow?: boolean;
  trackingParameters?: { [key: string]: string };
  trackingUniqueId?: string;
  signupParams?: { [key: string]: string | boolean };
}

export default function SignupForm({
  onRedirect,
  promptLogin,
  isTeacherFlow,
  trackingParameters,
  trackingUniqueId,
  signupParams,
}: SignupFormProps) {
  const Link = useLinkComponent();
  const { t } = useTranslation("ssr-client\\components\\SignupForm");

  return (
    <Box
      sx={{
        display: "grid",
        gap: "1em",
        width: "100%",
      }}
    >
      <SignupButtons
        isTeacherFlow={isTeacherFlow}
        trackingParameters={trackingParameters}
        trackingUniqueId={trackingUniqueId}
        onRedirect={onRedirect}
        signupParams={signupParams}
      />
      {promptLogin ? (
        <Trans t={t as TFunction}>
          <Box
            sx={{
              justifySelf: "center",
            }}
          >
            Already have an account?{" "}
            <Link
              data-test-id="login-link"
              onClick={onRedirect}
              to={addParamsToUrl(loginPath(), {
                postLoginPath: getPostLoginPath(),
              })}
              trackingName="login"
              trackingUniqueId={trackingUniqueId}
            >
              Log in
            </Link>
          </Box>
        </Trans>
      ) : null}
      <AccountDisclaimer isSignup />
    </Box>
  );
}

function SignupButtons({
  isTeacherFlow,
  trackingParameters,
  trackingUniqueId,
  onRedirect,
  signupParams,
}) {
  const Link = useLinkComponent();
  const { t } = useTranslation("ssr-client\\components\\SignupForm");
  const { setTokens } = useTokenContext();
  const [userFeedbackMessage, setUserFeedbackMessage] =
    React.useState<React.ReactNode>(null);
  const localStorage = isLocalStorageSupported();
  const canShowGoogleAuth = useCanShowGoogleAuth();

  const createParams = {
    isGiftCardSignup: signupParams?.isGiftCardSignup,
    isLeader: isTeacherFlow,
    trackingParameters,
  };

  const handleAuthError = React.useCallback(
    (error, message: React.ReactNode = "Sorry that didn't work, try again") => {
      OsPlatform.captureError(error, {
        tags: { component: Component.UserAccountManagement },
      });
      setUserFeedbackMessage(message);
    },
    [setUserFeedbackMessage]
  );

  const generateLoginError = useAuthError();

  const handleLoginSuccess = React.useCallback(
    async ({
      sessionToken,
      refreshToken,
      postLoginPath,
      isLearnerTransfer,
    }) => {
      if (!isLearnerTransfer) {
        setTokens(sessionToken, refreshToken);

        const redirectPath = isTeacherFlow ? teachPath() : postLoginPath;
        window.history.pushState({}, "", redirectPath);
        window.location.reload();
      } else {
        switchToLearnerModeWithToken({
          isReviewApp,
          transferToken: sessionToken,
          isLearnerLogin: true,
        });
      }
    },
    [isTeacherFlow, setTokens]
  );

  const handleLoginError = React.useCallback(
    error => {
      const errorObject = new Error(error);
      handleAuthError(errorObject, generateLoginError(error));
    },
    [handleAuthError, generateLoginError]
  );

  const handleAppleLogin = useLoginWithApple(
    handleLoginSuccess,
    handleLoginError,
    createParams
  );

  const setAuthStrategy = useLoginWithRedirect(handleLoginError, createParams);

  const showKakao = useShouldShowKakao();
  const showLine = useShouldShowLine();

  return (
    <React.Fragment>
      <LoginUserFeedback
        userFeedbackMessage={userFeedbackMessage}
        isError={true}
      />
      {canShowGoogleAuth && (
        <ContinueWithGoogle
          trackingName="signup-with-google"
          setAuthStrategy={setAuthStrategy}
          clientId={GOOGLE_CLIENT_ID!}
        />
      )}

      {FF_LINE_AUTH && showLine && (
        <ContinueWithLine
          isLearnerApp={false}
          setAuthStrategy={setAuthStrategy}
          trackingName="signup-with-line"
        />
      )}

      {FF_KAKAO_AUTH && showKakao && (
        <ContinueWithKakao
          isLearnerApp={false}
          setAuthStrategy={setAuthStrategy}
          trackingName="signup-with-kakao"
        />
      )}

      <ContinueWithFacebook
        trackingName="signup-with-facebook"
        setAuthStrategy={setAuthStrategy}
        clientId={FACEBOOK_APP_ID!}
      />

      <ContinueWithApple
        onSuccess={handleAppleLogin}
        onError={handleAuthError}
        trackingName="signup-with-apple"
        clientId={APPLE_SIGNIN_CLIENT_ID!}
      />
      <TrackedButton
        component={Link}
        onClick={() => {
          const postLoginAction = localStorage.getItem(
            APP_STATE_POST_LOGIN_ACTION_LOCAL_STORAGE_NAME
          );

          // temporary hack to set postloginpath & postloginaction in client-side website
          setAuthStrategy(
            "google",
            postLoginAction ? JSON.parse(postLoginAction) : null
          );

          onRedirect();
        }}
        data-test-id="signup-flow-link"
        trackingName="signup-with-email"
        trackingUniqueId={trackingUniqueId}
        to={
          isTeacherFlow
            ? joinTeacherAccountPath()
            : joinParentAccountPath(signupParams)
        }
        sx={{
          padding: "0 !important",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            fontSize: "1.5em",
            padding: "10px",
            width: "44px",
          }}
        >
          <Icon
            icon={faEnvelope}
            sx={{
              display: "block",
            }}
          />
        </Box>
        <Box
          flex
          sx={{
            flex: 1,
            padding: "12.5px",
            minWidth: "215px",
            justifyContent: "center",
          }}
        >
          {t`Sign Up with Email`}
        </Box>
      </TrackedButton>
    </React.Fragment>
  );
}
